.mobile-verifier-body{
	display: flex;
	min-height: 70vh;
	justify-content: center;
	align-items: center;
}
.title{
	font-size: 1.5rem;
	font-weight: 500;
	color: #37364C;
}

.mb-2.MuiFormControl-root{
	margin-bottom: 1.2rem;
}
  
button.primary-color{
	background-color: #FFD70F;
	padding: 0.625rem 1.75rem;
	color: #37364C;
	&:hover{
		background-color: #FFD70F;
	}
}
button.secondary-color{
	background-color: #2E3168;
	color: #ffffff;
	padding: 0.625rem 1.75rem;
	&:hover{
		background-color: #2E3168;
		color: #ffffff;
	}
}