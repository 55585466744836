.navbar.MuiAppBar-colorPrimary{
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: #FFD70F;
	box-shadow: none;
}
.ml-auto{
	margin-left: auto;
}
.navbar .MuiContainer-root.MuiContainer-maxWidthLg{
	padding-left: 0;
	padding-right: 0;
}
.navbar .ml-auto .nav-link{
	text-decoration: none;
	padding: 0 1rem;
	color: #37364c;
	transition: 0.3s;
}
.sidebar-trigger-mobile.MuiButtonBase-root{
	display: none;
}
.mr-1{
	margin-right: 10px;
}
@media screen and (max-width: 768px){
	.navbar .ml-auto{
		display: none;
	}
	.sidebar-trigger-mobile.MuiButtonBase-root{
		display: inline-flex;
		margin-left: auto;
	}
}
//37364C