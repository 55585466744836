.pricing-table-title{
	font-size: 2.625rem;
	color: #37364C;
	text-align: center;
}
.pricing-table{
	border-radius: 10px;
	height: 100%;
	box-shadow: 0px 4px 10px rgba(#000000,0.05);
	overflow: hidden;
	transition: 1s;
	&:hover{
		box-shadow: 0px 15px 40px rgba(#000000,0.15);
	}
	&.primary{
		.pricing-table-header{
			background-color: #FFD70F;
			color: #37364C;
		}
		.pricing-table-header-body{
			background-color: rgba(#FFD70F,0.2);
			color: #37364C;
		}
	}
	&.dull{
		.pricing-table-header{
			background-color: rgba(#2E3168,0.15);
			color: #37364C;
		}
		.pricing-table-header-body{
			background-color: rgba(#2E3168,0.05);
			color: #37364C;
		}
	}
	&.dark{
		.pricing-table-header{
			background-color: #2E3168;
			color: #ffffff;
		}
		.pricing-table-header-body{
			background-color: rgba(#2E3168,0.5);
			color: #ffffff;
		}
	}
	.pricing-table-header{
		min-height: 5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		h2{
			margin: 0;
			font-size: 2.25rem;
		}
	}
	.pricing-table-header-body{
		min-height: 10rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		h2{
			margin: 0;
			font-size: 3rem;
		}
	}
	.pricing-table-body{
		padding: 0.875rem 1.875rem;
		p{
			line-height: 2.5rem;
			margin: 0;
			border-bottom: 1px solid rgba(#2E3168,0.3);
			&:nth-last-child(1){
				border-bottom: none !important;
			}
			img{
				width: 1rem;
				margin-left: 0.625rem;
				margin-right: 0.625rem;
			}
		}
	}
	.pricing-table-footer{
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 1rem 3rem;
	}
}
.btn.primary-btn{
	background-color: #FFD70F;
	padding: 0.625rem 1.75rem;
	box-shadow: none;
	transition: 0.4s;
	color: #37364C;
	&:hover{
		background-color: #FFD70F;
		box-shadow: 0px 3px 6px rgba(#000000,0.2);
	}
}
.btn.link-btn{
	color: #2E3168;
	margin-top: 0.625rem;
}
.btn.outline-btn{
	padding: 0.625rem 1.75rem;
	color: #2E3168;
	// border: 2px solid #2E3168;
	box-shadow:inset 0px 0px 0px 2px #2E3168;
	border: none;
	&:hover{
		background-color: rgba(#2E3168,0.05);
	}
}