body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.h100{
  height: 100%;
  background-color: #f2f2f2;
}
.f-primary-title{
  font-size: 1.6rem;
  margin: 0.625rem 0;
  color: #37364c;
}
.f-small-title{
  margin: 0.625rem 0;
  color: #37364c;
}